<template>
	<section v-if="reviews.length" class="main-content">
		<div class="row">
			<div class="columns column12 align-center">
				<h2>{{ header }}</h2>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 review-slider">
				<client-only>
					<carousel :per-page="1" :pagination-position="'bottom-overlay'" class="carousel">
						<slide v-for="review in reviews" :key="review.ID">
							<div class="review-slider-content">
								<p>{{ review.content }}</p>
								<hr />
								<div class="source">
									<strong>{{ review.firstName }}</strong>
									<span v-if="review.date">
										{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
									</span>
									<span class="source-logo" :class="review.source" />
								</div>
							</div>
						</slide>

						<template #addons>
							<pagination />
						</template>
					</carousel>
				</client-only>
			</div>
		</div>
	</section>
</template>

<script setup>
const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviews', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});
</script>

<style lang="scss" scoped>
.review-slider {
	padding: 25px 250px 50px;
	overflow: hidden;

	&::before {
		content: '';
		display: inline-block;
		width: 64px;
		height: 48px;
		background: url('~/assets/images/reviews-quotes.png');
		position: absolute;
		top: 65px;
		left: 130px;
	}

	.review-slider-content {
		text-align: left;

		hr {
			margin: 25px 0;
			opacity: 0.2;
		}

		.source {
			width: 100%;
			float: left;
			margin: 10px 0;

			span,
			strong {
				float: left;
				margin: 0 4px 0 0;
			}

			.source-logo {
				display: block;
				float: right;
				margin: -21px 15px 0 20px;

				img {
					max-width: 100px;
					margin: 0;
				}
			}
		}
	}
}

/* stylelint-disable selector-class-pattern */
:deep(.carousel__pagination-item) {
	margin: 0 4px;

	button {
		width: 11px;
		height: 11px;
		border: 1px solid #fff;
		border-radius: 50%;
		background: rgb(239 239 239);

		&.carousel__pagination-button--active {
			background: #222;
		}

		&::after {
			display: none;
		}
	}
}
/* stylelint-enable selector-class-pattern */

@media (max-width: 900px) {
	.review-slider {
		padding: 50px 90px;
		overflow: hidden;

		&::before {
			display: none;
		}
	}
}

@media (max-width: 768px) {
	.review-slider {
		padding: 50px 70px;
		overflow: hidden;

		.review-slider-content p .review-source {
			display: block;
			float: right;
			margin: 40px 0 0 20px;
		}

		.review-slider-content .source .source-logo {
			margin: 20px 0 0;
		}
	}
}

@media (max-width: 600px) {
	.review-slider {
		padding: 20px;
	}
}
</style>
